import styled from 'styled-components';
import { contentMaxWidth } from '../../globalVars';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkList = styled.ul`
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: flex-start;
  //grid-template-columns: repeat(3, minmax(85px, 220px));
  //gap: 40px;
  padding: 20px 0;
  margin-bottom: 550px;

  @media screen and (max-width: 570px) {
    flex-direction: column;
  }

  /* @media screen and (max-width: 700px) {
    padding: 32px 0 16px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 32px 0 16px;
    gap: 16px;
  }
  @media screen and (max-width: 340px) {
    width: 100%;
    padding: 32px 4px 16px;
    gap: 5px;
  } */
`;

export const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 8vw;
  //max-width: 220px;
  //width: 100%;
`;

export const LinkTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #aaa;
  margin-bottom: 16px;

  @media screen and (max-width: 570px) {
    margin-bottom: 0;
  }

  /* @media screen and (max-width: 340px) {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
  } */
`;

export const LinkItem = styled.a`
  font-size: 18px;
  line-height: 30px;
  color: #ddd;
  margin-bottom: 16px;
  //transition: 0.15s ease;
  position: relative;
  left: 0;

  &:hover {
    font-weight: 500;
    //background-color: #2f1b48;
    color: #fff;
    //border-bottom: 1px solid white;
    //font-size: 20px;
  }

  /* @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 28px;
    display: flex;
  }

  @media screen and (max-width: 340px) {
    font-size: 8px;
    line-height: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  } */
`;

export const SocialIconsContainer = styled.div`
  max-width: ${contentMaxWidth};
  display: flex;
  justify-content: space-between;

  /* @media screen and (max-width: 500px) {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 340px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  } */
`;

export const CompanyContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-right: auto;

  /* @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: baseline;
  }

  @media screen and (max-width: 340px) {
    display: flex;
    flex-direction: column;
    margin: 0 0 32px;
    align-items: center;
  } */
`;

export const Slogan = styled.p`
  color: rgba(255, 255, 255, 0.5);
  min-width: 280px;
  letter-spacing: 0.02em;
  font-size: 18px;
  line-height: 30px;
  padding: 1rem;

  /* @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 340px) {
    line-height: 22px;
    font-size: 14px;
    min-width: 100px;
  } */
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;

  /* @media screen and (max-width: 500px) {
    justify-content: center;
    padding-right: 16px;
    flex-wrap: wrap;
  } */
`;

export const ExtraPadding = styled.div`
  height: 0;
  //margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    height: 57px;
  }
`;

export const FooterText = styled.p`
  font-size: 16px;
  color: #555;
  padding-bottom: 20px;
  padding-left: 2vw;
`;
