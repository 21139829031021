import { useState } from 'react';
import { Image } from './BackgroundImageStyles';

const BackgroundImage = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  console.log(isImageLoaded);

  const imageLoadedHandler = () => {
    setIsImageLoaded(true);
  };

  return (
    <>
      <Image
        onLoad={imageLoadedHandler}
        src='/images/bg-image-performant.webp'
        alt='background image'
      />
      {isImageLoaded ? null : (
        <Image temp src='/images/bg-image-tiny.jpg' alt='background image' />
      )}
    </>
  );
};

export default BackgroundImage;
