import NextSteps from '../NextSteps/NextSteps';
import Hero from '../Hero/Hero';
import Projects from '../Projects/Projects';
import Skills from '../Skills/Skills';
import WorkExperience from '../WorkExperience/WorkExperience.component';
import Education from '../Education/Education.component';

import { MainContentContainer } from '../MainContent/MainContent.styles';

const MainContent = () => {
  return (
    <MainContentContainer>
      <Hero />
      <Projects />
      <Skills />
      {/*
      ADD THESE BACK IN
      <WorkExperience />
      <Education />
      <NextSteps /> */}
    </MainContentContainer>
  );
};

export default MainContent;
