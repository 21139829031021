//import React from 'react';
import { DiCss3, DiSass, DiHtml5, DiFirebase } from 'react-icons/di';
import {
  SiReact,
  SiRedux,
  SiReduxsaga,
  SiJavascript,
  SiTypescript,
  SiFirebase,
  SiStyledcomponents,
  SiCplusplus,
  SiGithubactions,
  SiGnubash,
} from 'react-icons/si';
import { FaMobileAlt, FaDesktop } from 'react-icons/fa';

import {
  Section,
  SectionDivider,
  SectionText,
  SectionTitle,
} from '../Section/Section.styles';
import {
  List,
  ListItem,
  ListParagraph,
  ListTitle,
  ListIcons,
} from './SkillsStyles';

const Skills = () => {
  const iconStyling = { margin: '0 10px' };

  return (
    <Section id='skills'>
      <SectionDivider />
      <br />
      <SectionTitle>Skills</SectionTitle>
      {/* <SectionText>
        I've worked with a range of technologies
      </SectionText> */}
      <List>
        <ListItem>
          <ListIcons>
            <SiReact
              size='3.5rem'
              style={{ ...iconStyling, color: '#00D1F7' }}
            />
          </ListIcons>
          <ListTitle>React</ListTitle>
          <ListParagraph>
            Web apps and UIs using the React library
          </ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <SiRedux size='3rem' style={{ ...iconStyling, color: '#7248B6' }} />
            <SiReduxsaga
              size='3rem'
              style={{ ...iconStyling, color: '#949494' }}
            />
          </ListIcons>
          <ListTitle>Redux &#38; Saga</ListTitle>
          <ListParagraph>
            Redux state management, including Saga for side effects
          </ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <SiJavascript
              size='3rem'
              style={{ ...iconStyling, color: '#EFD81D' }}
            />
            <SiTypescript
              size='3rem'
              style={{ ...iconStyling, color: '#23BDF5' }}
            />
          </ListIcons>
          <ListTitle>JS &#38; TS</ListTitle>
          <ListParagraph>Type-safe JavaScript using TypeScript</ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <DiCss3
              size='3.5rem'
              style={{ ...iconStyling, color: '#2862E9' }}
            />
            <DiSass
              size='3.5rem'
              style={{ ...iconStyling, color: '#C66394' }}
            />
            <DiHtml5
              size='3.5rem'
              style={{ ...iconStyling, color: '#E96228' }}
            />
          </ListIcons>
          <ListTitle>HTML, (S)CSS</ListTitle>
          <ListParagraph>Semantic HTML and SASS-enhanced CSS</ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <SiFirebase
              size='3rem'
              style={{ ...iconStyling, color: '#F7A00F' }}
            />
            <DiFirebase
              size='3.5rem'
              style={{ ...iconStyling, color: '#1F84F7' }}
            />
          </ListIcons>
          <ListTitle>Firebase &#38; Firestore</ListTitle>
          <ListParagraph>Firebase user auth and Firestore DB</ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <SiStyledcomponents
              size='3.5rem'
              style={{ ...iconStyling, color: '#E07EBE' }}
            />
          </ListIcons>
          <ListTitle>Styled Components</ListTitle>
          <ListParagraph>Experience with CSS-in-JS</ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <SiCplusplus
              size='3rem'
              style={{ ...iconStyling, color: '#23BDF5' }}
            />
          </ListIcons>
          <ListTitle>C++</ListTitle>
          <ListParagraph>
            From game dev to MIDI art to Unreal Engine plugins
          </ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <SiGithubactions
              size='3rem'
              style={{ ...iconStyling, color: '#1F84F7' }}
            />
          </ListIcons>
          <ListTitle>GitHub Actions</ListTitle>
          <ListParagraph>
            Cross-platform builds and testing with YAML
          </ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <FaDesktop size='3rem' style={{ ...iconStyling, color: '#999' }} />
            <FaMobileAlt
              size='3rem'
              style={{ ...iconStyling, color: '#eee' }}
            />
          </ListIcons>
          <ListTitle>Responsive Design</ListTitle>
          <ListParagraph>Sites that look good at any size</ListParagraph>
        </ListItem>

        <ListItem>
          <ListIcons>
            <SiGnubash size='3rem' style={{ ...iconStyling, color: '#eee' }} />
          </ListIcons>
          <ListTitle>Bash Scripting</ListTitle>
          <ListParagraph>Build, test, parsing scripts and more</ListParagraph>
        </ListItem>
      </List>
      {/* <SectionDivider colorAlt /> */}
    </Section>
  );
};

export default Skills;
