//import React from 'react';
// import {
//   AiFillGithub,
//   AiFillTwitterCircle,
//   AiFillLinkedin,
//   AiOutlineMail,
//   AiOutlinePhone,
// } from 'react-icons/ai';

import { SocialIcons } from '../navbar/navbar.styles';
import {
  // CompanyContainer,
  ContactContainer,
  LinkColumn,
  LinkItem,
  LinkList,
  LinkTitle,
  ExtraPadding,
  FooterText,
  // Slogan,
  // SocialContainer,
  // SocialIconsContainer,
} from './ContactStyles';

import {
  Section,
  SectionDivider,
  SectionTitle,
} from '../Section/Section.styles';

// import { socialIconSize } from '../../globalVars';

const Contact = () => {
  return (
    <Section id='contact'>
      <SectionDivider />
      <br />
      <SectionTitle>Contact Info</SectionTitle>
      <ContactContainer>
        <LinkList>
          <LinkColumn>
            <LinkTitle>Email</LinkTitle>
            <LinkItem href='mailto:sammiller85@protonmail.com'>
              sammiller85@protonmail.com
            </LinkItem>
          </LinkColumn>
          <LinkColumn>
            <LinkTitle>Phone</LinkTitle>
            <LinkItem href='tel:530-680-5998'>530-680-5998</LinkItem>
          </LinkColumn>
        </LinkList>
      </ContactContainer>
      <FooterText>
        Background texture by @pawel_czerwinski on Unsplash
      </FooterText>
      <ExtraPadding />
    </Section>
  );
};

export default Contact;
