import styled from 'styled-components';

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 57px;
  border-top: solid 2px #999;
  border-bottom: solid 2px #999;
  z-index: 5;
  position: fixed;

  top: 0;

  padding: 0 15px;

  @media screen and (max-width: 600px) {
    justify-content: space-around;
  }
`;

export const Span = styled.span`
  font-size: 1.6rem;
  white-space: nowrap;
`;

export const Name = styled.div`
  /* grid-area: 1 / 1 / 2 / 2; */
  @media screen and (max-width: 810px) {
    display: none;
  }
`;
export const NavLinks = styled.div`
  //width: clamp(40vw, 40vw, 50vw);
  display: flex;
  justify-content: space-between;
  //padding: 0 1rem;
  /* grid-area: 1 / 2 / 2 / 4; */
  //display: flex;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

// Navigation Links
export const NavLink = styled.a`
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0 1vw;
  //line-height: 3rem;
  color: rgba(255, 255, 255, 0.75);
  transition: 0.2s ease;
  &:hover {
    color: #fff;
    opacity: 1;
    cursor: pointer;
    background-color: #3d0345;
  }
  @media screen and (max-width: 600px) {
    /* grid-area: 2 / 2 / 3 / 5; */
    //width: 100%;
    //margin: 0;
    //margin: 0 3vw;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    display: none;
    /* align-items: center; */
  }
`;

export const SocialIcons = styled.a`
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  color: #ddd;
  border-radius: 50px;
  padding-left: 10px;
  &:hover {
    //background-color: #212d45;
    color: #fff;
    transform: scale(1.2);
    cursor: pointer;
  }
`;
