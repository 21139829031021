import {
  AiFillGithub,
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiOutlineMail,
  AiOutlinePhone,
} from 'react-icons/ai';
import { BsBriefcaseFill } from 'react-icons/bs';

import {
  NavbarContainer,
  Name,
  //   NavLinks,
  SocialLinks,
  //   NavLink,
  SocialIcons,
  Span,
} from './navbar-footer.styles';

import { socialIconSize } from '../../globalVars';

const NavbarFooter = () => (
  <NavbarContainer>
    <Name>
      <a
        href='#top'
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          //marginBottom: '20px',
        }}
      >
        <BsBriefcaseFill size='2rem' /> <Span>&nbsp;Sam Miller</Span>
      </a>
    </Name>
    <SocialLinks>
      <SocialIcons
        href='https://github.com/slimrealm'
        target='_blank'
        rel='noopener noreferrer'
      >
        <AiFillGithub size={socialIconSize} />
      </SocialIcons>
      <SocialIcons
        href='https://x.com/slimrealm1'
        target='_blank'
        rel='noopener noreferrer'
      >
        <AiFillTwitterCircle size={socialIconSize} />
      </SocialIcons>
      <SocialIcons
        href='https://www.linkedin.com/in/sam-miller-info'
        target='_blank'
        rel='noopener noreferrer'
      >
        <AiFillLinkedin size={socialIconSize} />
      </SocialIcons>
      <SocialIcons
        href='mailto:sammiller85@protonmail.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        <AiOutlineMail size={socialIconSize} />
      </SocialIcons>
      <SocialIcons
        href='tel:530-680-5998'
        target='_blank'
        rel='noopener noreferrer'
      >
        <AiOutlinePhone size={socialIconSize} />
      </SocialIcons>
    </SocialLinks>
  </NavbarContainer>
);

export default NavbarFooter;
