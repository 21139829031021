import styled from 'styled-components';

export const ImageContainer = styled.div`
  text-align: center;
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    rgba(79, 108, 176, 0.25) 53.8%,
    rgba(79, 108, 176, 0) 100%
  );
  width: 100%;
  padding: 60px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* @media screen and (max-width: 700px) {
    background-image: none;
    padding: 0;
    margin-top: 40px;
  }
  @media screen and (max-width: 500px) {
    background-image: none;
    padding: 0;
    margin-top: 16px;
  } */
`;

export const MainImage = styled.img`
  width: 100%;
`;

export const List = styled.div`
  //list-style-type: none;
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  //grid-auto-rows: 1fr;
  //grid-auto-columns: 1fr;

  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  //grid-template-rows: repeat(auto-fill, minmax(150px, 250px));
  align-items: center;
  //align-self: space-around;
  //justify-items: stretch;
  //place-items: ;
  gap: 20px;
  margin: 30px 0 20px 0;
  padding: 0;

  @media only screen and (min-width: 425px) and (max-width: 582px) {
    margin: 30px 7vw 20px 7vw;
  }

  @media screen and (max-width: 700px) {
    //margin: 64px 0;
  }

  @media screen and (max-width: 500px) {
    //margin: 64px 0;
    //gap: 24px;
  }

  @media screen and (max-width: 340px) {
    //display: flex;
    //flex-direction: column;
    //margin: 32px 0;
  }
`;

export const ListItem = styled.li`
  //max-width: 320px;
  display: flex;
  background: linear-gradient(95deg, #1f1030, #0d0315);
  border-radius: 10px;
  border: solid 2px #666;
  //height: 250px;
  padding: 15px;
  //width: minmax(250px, 1fr);
  //box-sizing: border-box;
  //justify-self: center;
  //margin: auto;

  //width: clamp(100px, 200px, 300px);
  max-width: 400px;
  aspect-ratio: 1;

  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 500px) {
    //max-width: 203px;
  }

  @media screen and (max-width: 340px) {
    //margin-bottom: 14px;
    //max-width: 320px;
    //flex-direction: row;
  }
`;

export const ListIcons = styled.div`
  //background-color: white;
  display: flex;
  color: white;
  //justify-content: center;
  //width: 48px;
  height: 48px;
  //margin: 0;
`;

export const ListTitle = styled.h4`
  font-weight: 500;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #9cc9e3;
  margin: 0;
  /*
  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media screen and (max-width: 340px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin-bottom: 4px;
  } */
`;

export const ListParagraph = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);

  @media screen and (max-width: 500px) {
    //font-size: 16px;
    //line-height: 28px;
  }

  @media screen and (max-width: 340px) {
    //font-size: 14px;
    //line-height: 22px;
  }
`;

// export const ListIcon = styled.img`
//   display: block;
//   color: 'white';
//   width: 48px;
//   height: 48px;
//   margin-bottom: 10px;

//   @media screen and (max-width: 500px) {
//     width: 40px;
//     height: 40px;
//     margin-bottom: 8px;
//   }

//   @media screen and (max-width: 340px) {
//     width: 32px;
//     height: 32px;
//     margin-bottom: 0px;
//   }
// `;
