import styled from 'styled-components';

export const HeroContainer = styled.div`
  margin-top: 50px;
`;

export const LeftSection = styled.div`
  margin-left: 7vw;
  align-self: flex-start;
  max-width: 550px;

  @media screen and (min-width: 1200px) {
    margin-top: 4vw;
  }

  @media screen and (max-width: 560px) {
    min-width: 300px;
  }
`;
