import Navbar from './components/navbar/navbar.component';
import NavbarFooter from './components/navbar-footer/navbar-footer.component';
import MainContent from './components/MainContent/MainContent.component';
import Contact from './components/Contact/Contact';
import BackgroundImage from './components/BackgroundImage/BackgroundImage';

import { GlobalStyle } from './global.styles';

function App() {
  return (
    <>
      <GlobalStyle />
      <BackgroundImage />
      <Navbar />
      <MainContent />
      <Contact />
      <NavbarFooter />
    </>
  );
}

export default App;
