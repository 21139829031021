import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

body::before {
  //opacity: 0.1;
}

html {
  //scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  //background-color: #0D0315;

  width: 100%;
  //margin: auto;

  font-family: 'Tomorrow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* @media screen and (max-width: 800px) {
    padding: 0 15px;
  } */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

a {
  text-decoration: none;
  //color: black;
}

* {
  box-sizing: border-box;
}
`;
