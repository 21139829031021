import React from 'react';

export const projects = [
  {
    title: 'Samstagram',
    description:
      'My clone of the web version of Instagram, matching styling, color, typography, and layout changes across size breaks.',
    image: '/images/samstagram.png',
    tags: [
      'HTML',
      '·',
      'SCSS',
      '·',
      'Layout Cloning',
      '·',
      'Responsive Design',
    ],
    source: 'https://github.com/slimrealm/samstagram',
    site: 'https://samstagram.netlify.app',
    id: 1,
  },
  {
    title: 'CRWN Clothing',
    description: `A clothing store, made with React and TypeScript, where you can shop by category, add / clear / change quantity of items in your shopping cart, and then checkout with credit card via Stripe. \xa0 Cart is persisted across sessions using Redux-Persist.`,
    image: '/images/crwncart.png',
    tags: [
      'React',
      '·',
      'Redux',
      '·',
      'Redux-Saga',
      '·',
      'TypeScript',
      '·',
      'Stripe',
      '·',
      'Firebase',
      '·',
      'Styled Components',
    ],
    source: 'https://github.com/slimrealm/crwn-clothing',
    site: 'https://sam-crwn.netlify.app',
    id: 0,
  },
  // {
  //   title: 'ShareMe',
  //   description:
  //     'A Pinterest-style photo sharing app where you can upload and save pins, as well as search uploaded pins by category, title, and description.  \xa0  React Google Login is used for sign in / sign out.  \xa0 Pin tiling is done with React Masonry CSS.  \xa0  Sanity is used for backend / database.',
  //   image: '/images/shareme.png',
  //   tags: [
  //     'React',
  //     '·',
  //     'DB Queries',
  //     '·',
  //     'Tailwind CSS',
  //     '·',
  //     'Sanity.io',
  //     '·',
  //     'React Masonry CSS',
  //   ],
  //   source: 'https://github.com/slimrealm/share-me-app',
  //   site: 'https://samshareme.netlify.app',
  //   id: 2,
  // },
  {
    title: 'MIDI Art Lab',
    description:
      'My art generating program that takes input from a connected MIDI controller / keyboard, and uses the received MIDI parameters to define how and where shapes are added to the screen. \xa0 Example output images from each of the drawing algorithms in the README of the linked ^ GitHub repo.',
    image: '/images/mallow.png',
    tags: [
      'C++',
      '·',
      'Algorithm Design',
      '·',
      'MIDI Processing',
      '·',
      'SFML Library',
    ],
    source: 'https://github.com/slimrealm/cpp-midi-art-lab',
    //site: '',
    id: 3,
  },
];

export const nextStepsData = [
  { topic: 'Jest, Cypress', text: 'Students' },
  {
    topic: 'Cloud dev, Server management, Monitoring, IaC',
    text: 'Open Source Projects',
  },
  { topic: 'Web3', text: 'Students' },
  { topic: 'Component libraries', text: 'Github Stars' },
  { topic: 'SSR & SSG', text: 'Students' },
];
