//import React from 'react';

import {
  Section,
  SectionText,
  SectionTitle,
  SectionAccent,
} from '../Section/Section.styles';
import Button from '../Button/Button';
import { HeroContainer, LeftSection } from '../Hero/HeroStyles';

import BgAnimation from '../BackgroundAnimation/BackgroundAnimation';

const Hero = () => (
  <HeroContainer>
    <Section grid hero>
      {/* <HeroContent> */}
      {/* <Section> */}
      <LeftSection>
        <SectionTitle>
          Sam Miller, <SectionAccent>Full Stack Engineer.</SectionAccent>
        </SectionTitle>
        <br />
        <SectionText>
          An experienced software engineer with a proven track record in designing, building, testing, and deploying quality software.  Aiming to leverage my skills to help a dynamic company deliver high-impact solutions and achieve its strategic goals.</SectionText>
        {/* <Button onClick={() => (window.location = 'https://google.com')}>
          Contact
        </Button> */}
      </LeftSection>
      {/* </Section> */}
      {/* </HeroContent> */}
      <BgAnimation />
    </Section>
  </HeroContainer>
);

export default Hero;
