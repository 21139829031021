import styled from 'styled-components';

export const NavbarContainer = styled.div`
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: space-between;

    align-items: center;
    background-color: black;
    width: 100%;
    height: 57px;
    border-top: solid 2px #999;
    border-bottom: solid 2px #999;
    z-index: 5;
    position: fixed;

    bottom: 0;

    padding: 0 15px;
  }
`;

export const Span = styled.span`
  font-size: 1.6rem;
  white-space: nowrap;

  @media screen and (max-width: 440px) {
    display: none;
  }
`;

export const Name = styled.div`
  /* @media screen and (max-width: 810px) {
    display: none;
  } */

  @media screen and (max-width: 600px) {
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  //@media screen and (max-width: 600px) {
  //display: none;
  /* align-items: center; */
  //}
`;

export const SocialIcons = styled.a`
  display: flex;
  align-items: center;
  transition: 0.2s ease;
  color: white;
  border-radius: 50px;
  padding-left: 10px;
  &:hover {
    //background-color: #212d45;
    color: #fff;
    transform: scale(1.2);
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
  }
`;
