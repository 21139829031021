import styled, { css } from 'styled-components';

const titleStyle = css`
  font-weight: 300;
  font-size: ${(props) => (props.main ? '65px' : '56px')};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0);
  margin: 0 0 0 0;

  @media screen and (max-width: 500px) {
    margin: 0;
  }
`;

export const Section = styled.section`
  display: ${(props) => (props.grid ? 'grid' : 'flex')};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  align-items: ${(props) => (props.hero ? 'center' : '')};
  justify-content: center;
  padding: 50px 32px 0 32px;
  margin: 0;
  position: relative;
  overflow: hidden;
  grid-template-columns: 1.5fr 1fr;

  @media screen and (max-width: 500px) {
    padding: 35px 12px 0 12px;
  }
`;

export const SectionTitle = styled.h2`
  ${titleStyle}
  color: #ddd;
`;

export const SectionAccent = styled.span`
  ${titleStyle}
  color: #00d1f7;
`;

export const SectionText = styled.p`
  max-width: 800px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin: 10px 0;
  color: #ddd;

  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`;

export const SectionDivider = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background-color: #fff;
  background: linear-gradient(270deg, #f46737 0%, #945dd6 100%);
`;
export const SectionSubText = styled.p`
  max-width: 800px;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.75);
`;
