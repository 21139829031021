import styled from 'styled-components';

export const Image = styled.img`
  position: fixed;
  width: 100%;
  height: 100%;

  background-color: #000;

  filter: ${(props) => (props.temp ? 'blur(10px)' : 'brightness(50%)')};
  /* mix-blend-mode: ${(props) => (props.temp ? '' : 'darken')}; */

  object-fit: cover;
`;
