//import React from 'react';

import {
  BlogCard,
  CardInfo,
  ExternalLink,
  GridContainer,
  HeaderThree,
  Separator,
  Tag,
  TagList,
  TitleContent,
  UtilityList,
  Img,
} from './ProjectsStyles';
import {
  Section,
  SectionDivider,
  SectionTitle,
} from '../Section/Section.styles';
import { projects } from '../../DataObjects/DataObjects';

const Projects = () => (
  <Section id='projects'>
    <SectionDivider />
    <br />
    <SectionTitle>Projects</SectionTitle>
    <GridContainer>
      {projects.map(
        ({ id, image, title, description, tags, source, site }, index) => (
          <BlogCard key={id}>
            <a
              href={site ? site : source}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Img src={image} />
            </a>
            <TitleContent>
              <HeaderThree>{title}</HeaderThree>
            </TitleContent>
            <UtilityList>
              {site && (
                <ExternalLink
                  href={site}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Live Site
                </ExternalLink>
              )}
              <ExternalLink
                href={source}
                target='_blank'
                rel='noopener noreferrer'
              >
                Source Code
              </ExternalLink>
            </UtilityList>
            <div>
              <TagList>
                {tags.map((tag, index) => (
                  <Tag key={index}>{tag}</Tag>
                ))}
              </TagList>
            </div>
            <Separator />
            <CardInfo>{description}</CardInfo>
          </BlogCard>
        )
      )}
    </GridContainer>
  </Section>
);

export default Projects;
