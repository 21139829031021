import styled from 'styled-components';

export const Img = styled.img`
  margin-top: 15px;
  width: 95%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  opacity: 1;
  transition: 0.15s ease;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 470px) {
    //width: 85%;
  }
`;

export const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  padding: 1rem;
  margin-bottom: 1rem;
  place-items: center;
  column-gap: 2rem;
  row-gap: 3rem;

  @media screen and (max-width: 580px) {
    padding: 0;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }

  @media screen and (min-width: 1500px) {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    //place-items: flex-start;
    //margin-right: 500px;
  }

  /* @media screen and (max-width: 340px) {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-bottom: 0;
  } */
`;
export const BlogCard = styled.div`
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(80, 78, 78, 0.5);
  text-align: center;
  background-color: #13071f;
  //width: 400px;
  max-width: 600px; //100%;
  min-height: 800px;
  max-height: 800px;

  /* @media screen and (max-width: 340px) {
    width: 100%;
  } */
`;
export const TitleContent = styled.div`
  text-align: center;
  //z-index: 20;
  width: 100%;
`;

export const HeaderThree = styled.h3`
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #9cc9e3;
  padding: 0;
  margin: 20px 0;
  font-size: 2rem; /* ${(props) => (props.title ? '3rem' : '2rem')}; */
`;

export const Separator = styled.hr`
  border-radius: 50px;
  background-color: #fff;
  background: //linear-gradient(270deg, #f46737 0%, #945dd6 100%);
    linear-gradient(270deg, #13adc7 0%, #945dd6 100%);

  width: 80%;
  height: 4px;
  margin: 0px auto;
  border: 0;
  //background: #aaa;
`;

// export const Intro = styled.div`
//   width: 170px;
//   margin: 0 auto;
//   color: #dce3e7;
//   /* font-family: 'Droid Serif', serif; */
//   font-size: 13px;
//   font-style: italic;
//   line-height: 18px;
// `;

export const CardInfo = styled.p`
  width: 100%;
  padding: 10px 30px;
  color: #e4e6e7;
  font-style: 2rem;
  line-height: 24px;
  text-align: left;
  /* @media screen and (max-width: 340px) {
    padding: 0.3rem;
  } */
`;

export const UtilityList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0;
  display: flex;
  justify-content: space-around;
`;

export const ExternalLink = styled.a`
  color: #d4c0c0;
  background: linear-gradient(270deg, #ff622e 0%, #b133ff 100%);
  font-family: 'Tomorrow', sans-serif;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  //background: #6b3030;
  border: 2px solid #6b3030;
  border-radius: 15px;
  //transition: 0.2s;
  &:hover {
    //background: #801414;
    border: 2px solid white;
    color: white;
  }
`;

export const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  width: 100%;
`;

export const Tag = styled.li`
  color: #9cc9e3;
  font-size: 0.95rem;
  line-height: 1.3rem;
  font-weight: 600;
  padding: 0.2rem;
  list-style: none;
  //padding: 0 10px;
`;

// export const TagSeparator = styled.p`
//   color: #d8bfbf;
//   font-size: 1.2rem;
//   //padding: 0.2rem;
// `;
